'use client';

import { GoogleTagManager, GoogleAnalytics } from '@next/third-parties/google';
import { useCookieConsent } from '@/contexts/CookieConsentContext';

const GoogleTrack: React.FC = () => {
  const { consentGiven } = useCookieConsent();

  if (!consentGiven) {
    return null;
  }

  return (
    <>
      <GoogleTagManager gtmId="GTM-PRNHTGP2" />
      <GoogleAnalytics gaId="G-YTNBJX5K1M" />
    </>
  );
};

export default GoogleTrack;
