'use client';
import { CookieIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useState, useEffect } from 'react';
import { useCookieConsent } from '@/contexts/CookieConsentContext';
import Link from 'next/link';

export default function CookieConsent({
  demo = false,
  onAcceptCallback = () => {},
  onDeclineCallback = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(true);
  const { giveConsent, revokeConsent } = useCookieConsent();

  useEffect(() => {
    const timer = setTimeout(() => {
      const cookieConsent = document.cookie.includes('cookieConsent=true');
      if (!cookieConsent || demo) {
        setHide(false);
        requestAnimationFrame(() => setIsOpen(true));
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, [demo]);

  const accept = () => {
    setIsOpen(false);
    giveConsent();
    setTimeout(() => setHide(true), 700);
    onAcceptCallback();
    window.dispatchEvent(new Event('activateHotjar'));
  };

  const decline = () => {
    setIsOpen(false);
    revokeConsent();
    setTimeout(() => setHide(true), 700);
    onDeclineCallback();
  };

  if (hide) return null;

  return (
    <div
      className={cn(
        'fixed z-20 bottom-0 right-0 w-full transition-transform duration-700 max-w-sm md:max-w-md',
        !isOpen
          ? 'transition-[opacity,transform] translate-y-8 opacity-0'
          : 'transition-[opacity,transform] translate-y-0 opacity-100',
      )}
    >
      <div
        className="m-2 rounded-lg border bg-background"
        style={{
          contain: 'paint',
          contentVisibility: 'auto',
        }}
      >
        <div className="grid gap-2">
          <div className="flex h-14 items-center justify-between border-b border-border p-4">
            <p className="text-lg font-medium">Le site utilise des cookies</p>
            <CookieIcon className="size-6" />
          </div>
          <div className="p-4">
            <p
              className="text-sm font-normal"
              style={{ contentVisibility: 'auto' }}
            >
              <span className="block">
                Le site utilise des cookies pour améliorer votre expérience. Ces
                cookies aident à comprendre comment vous interagissez avec le
                contenu et à optimiser le site pour vous offrir une navigation
                fluide et personnalisée.
              </span>
              <span className="block mt-4 text-xs">
                En cliquant sur
                <span className="font-medium"> Accepter</span>, vous acceptez
                l&apos;utilisation des cookies.
              </span>
              <Link
                href="/cookie-policy"
                className="text-xs underline mt-2 inline-block"
              >
                En savoir plus.
              </Link>
            </p>
          </div>
          <div className="flex gap-2 border-t border-border bg-background/20 p-4 py-5">
            <Button onClick={decline} className="w-full" variant="secondary">
              Refuser
            </Button>
            <Button onClick={accept} className="w-full">
              Accepter
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
