import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { PhoneCall } from 'lucide-react';
import { CallToAction } from '@/components/common/CallToAction';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer';

const KeyToSuccess = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleClick = () => {
    if (window.innerWidth >= 768) {
      setOpenDialog(true);
    } else {
      setOpenDrawer(true);
    }
  };

  const content = (
    <div className="flex flex-col">
      <p className="mb-6 text-base leading-6 text-muted-foreground">
        <span>Une </span>
        <span className="font-bold text-foreground">bonne communication</span>
        <span> doit être au cœur de chaque projet.</span>
      </p>
      <p className="mb-6 text- text-muted-foreground leading-6 md:mb-3">
        Nous vous proposons un appel rapide,{' '}
        <span className="font-bold text-foreground">100% offert</span> et{' '}
        <span className="font-bold text-foreground">sans engagement</span> pour
        répondre à vos interrogations et discuter de vos objectifs.
      </p>
      <div onClick={handleClick}>
        <CallToAction
          fullWidth
          href={null}
          text="Appel gratuit"
          icon={<PhoneCall size={18} />}
        />
      </div>
    </div>
  );

  // Tableau des sections de contenu
  const sections = [
    {
      title: 'La🔑de la réussite',
      content: content,
    },
  ];

  return (
    <>
      <div className="md:max-w-sm">
        {/* Version desktop */}
        <div className="hidden md:block">
          {sections.map((section, index) => (
            <div key={index}>
              <h2 className="mb-2 text-3xl font-bold">{section.title}</h2>
              {section.content}
            </div>
          ))}
        </div>

        {/* Version mobile avec accordéon */}
        <div className="md:hidden">
          <Accordion type="single" collapsible defaultValue="item-1">
            <AccordionItem value="item-1" className="border-b-0">
              <AccordionTrigger className="mb-2 border-b text-xl font-bold ">
                {sections[0].title}
              </AccordionTrigger>
              <AccordionContent className="mx-2">
                {sections[0].content}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>

      {/* Dialog pour desktop */}
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="max-w-screen-2xl">
          <DialogHeader>
            <DialogTitle>Réserver un rendez-vous</DialogTitle>
          </DialogHeader>
          <iframe
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1t3yYzb4LtEZjzLuILUf8-fKIzYBrO3qIfXjzpTHJUDuWADnh9Y-zFHNQJ3VmUJZyUuSANMa8h?gv=true"
            width="100%"
            height="700"
            className="rounded-lg"
          ></iframe>
        </DialogContent>
      </Dialog>

      {/* Drawer pour mobile */}
      <Drawer open={openDrawer} onOpenChange={setOpenDrawer}>
        <DrawerContent>
          <DrawerHeader className="text-left">
            <DrawerTitle>Réserver un rendez-vous</DrawerTitle>
          </DrawerHeader>
          <iframe
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1t3yYzb4LtEZjzLuILUf8-fKIzYBrO3qIfXjzpTHJUDuWADnh9Y-zFHNQJ3VmUJZyUuSANMa8h?gv=true"
            width="100%"
            height="600"
            className="rounded-lg"
          ></iframe>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default KeyToSuccess;
