'use client';

import * as React from 'react';
import { MoonIcon, SunIcon } from 'lucide-react';
import { useTheme } from 'next-themes';
import { Button } from '@/components/ui/button';

export function ModeToggle() {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = React.useState(false);
  const isDark = theme === 'dark';

  // Attendre que le composant soit monté pour éviter les problèmes d'hydration
  React.useEffect(() => {
    setMounted(true);
  }, []);

  const toggleTheme = () => {
    setTheme(isDark ? 'light' : 'dark');
  };

  const label = isDark ? 'Passer au mode clair' : 'Passer au mode sombre';

  // Ne rien afficher jusqu'à ce que le composant soit monté
  if (!mounted) {
    return null;
  }

  return (
    <Button
      variant="ghost"
      className="fixed right-5 top-20 z-10 rounded-full hover:bg-transparent"
      onClick={toggleTheme}
      aria-label={label}
    >
      <div className="relative h-6 w-6">
        <SunIcon
          className={`absolute left-0 top-0 transition-all duration-200 !size-6 ${
            isDark ? 'rotate-[-90deg] scale-0' : 'rotate-0 scale-100'
          }`}
          size={24}
        />
        <MoonIcon
          className={`absolute left-0 top-0 transition-all duration-200 !size-6 ${
            isDark ? 'rotate-0 scale-100' : 'rotate-90 scale-0'
          }`}
          size={24}
        />
      </div>
      <span className="sr-only">{label}</span>
    </Button>
  );
}
