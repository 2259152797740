'use client';
import React from 'react';
import Link from 'next/link';
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import { Separator } from '@/components/ui/separator';
import { SendHorizontal } from 'lucide-react';
import { usePathname } from 'next/navigation';
import MaltIcon from '@/components/icons/MaltIcon';
import KeyToSuccess from './KeyToSuccess';
import Logo from '@/components/common/Logo';
import { CallToAction } from '@/components/common/CallToAction';

const Footer = () => {
  const currentPath = usePathname();

  const isActive = (path: string) => {
    return currentPath === path
      ? 'text-md md:text-lg font-bold'
      : 'text-muted-foreground text-md md:text-lg text-nowrap';
  };

  const links = [
    { href: '/', label: 'Accueil' },
    { href: '/a-propos', label: 'À propos' },
    { href: '/avis', label: 'Avis' },
    { href: '/projects', label: 'Projets' },
    { href: '/contact', label: 'Contact' },
  ];

  const legalLinks = [
    { href: '/cgv', label: 'Conditions générales de vente' },
    { href: '/cookie-policy', label: 'Politique de confidentialité' },
    { href: '/mentions-legales', label: 'Mentions légales' },
    { href: '/sitemap', label: 'Plan du site' },
  ];

  const socialLinks = [
    {
      href: 'https://github.com/ClementG91',
      label: 'Github',
      icon: (
        <BsGithub
          className="transition-all ease-in-out hover:scale-125"
          size={24}
        />
      ),
    },
    {
      href: 'https://www.linkedin.com/in/clementgenest/',
      label: 'Linkedin',
      icon: (
        <BsLinkedin
          className="transition-all ease-in-out hover:scale-125"
          size={24}
        />
      ),
    },
    {
      href: 'https://www.malt.fr/profile/clementgenest',
      label: 'Malt',
      icon: (
        <MaltIcon
          className="transition-all ease-in-out hover:scale-125"
          size={24}
        />
      ),
    },
  ];

  const memoizedKeyToSuccess = React.useMemo(() => <KeyToSuccess />, []);

  const contactButton = (
    <CallToAction
      text="Contactez-Nous"
      href="/contact"
      icon={<SendHorizontal />}
    />
  );

  return (
    <footer className="border-t">
      <div className="container flex flex-col pt-4 md:pt-8 ">
        <div className="flex flex-col justify-between items-center md:items-start gap-8 md:flex-row">
          {memoizedKeyToSuccess}
          <div className="mx-2 rounded-lg bg-white/10 p-3 md:hidden">
            <div className="pb-3">
              <p className="text-xl font-medium">
                Pour mes{' '}
                <span className="font-extrabold ">autres contacts</span>
              </p>
              <p className="text-nowrap text-2xl font-medium">
                c&apos;est par ici
              </p>
            </div>
            <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="flex gap-4">
                {socialLinks.map((link) => (
                  <Link
                    key={link.href}
                    href={link.href}
                    aria-label={link.label}
                    target="_blank"
                    rel="noopener"
                  >
                    {link.icon}
                  </Link>
                ))}
              </div>
              {contactButton}
            </div>
          </div>
          <Separator className=" bg-primary md:hidden" />
          <div className="flex flex-wrap w-full justify-start lg:justify-around gap-8">
            <div className="flex flex-col">
              <div className="mb-2 text-lg font-semibold">Navigation</div>
              {links.map((link) => (
                <Link
                  key={link.href}
                  href={link.href}
                  className={isActive(link.href)}
                >
                  {link.label}
                </Link>
              ))}
            </div>

            <div className="flex flex-col ">
              <div className="mb-2 text-lg font-semibold">Infos légales</div>
              {legalLinks.map((link) => (
                <Link
                  key={link.href}
                  href={link.href}
                  className={isActive(link.href)}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>

          <Logo />
        </div>

        <div className="relative hidden flex-col pt-8 md:flex">
          <div className="flex items-start justify-between">
            <div className="text-2xl font-medium text-muted-foreground">
              Pour nos{' '}
              <span className="font-extrabold text-foreground">
                autres contacts
              </span>
            </div>
            <div className="absolute right-0 top-2 flex space-x-4">
              {socialLinks.map((link) => (
                <Link
                  key={link.href}
                  href={link.href}
                  aria-label={link.label}
                  target="_blank"
                  rel="noopener"
                >
                  {link.icon}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-between gap-8">
            <p className="text-nowrap text-3xl font-medium text-muted-foreground">
              c&apos;est par ici
            </p>
            <div className="w-full">
              <Separator className="" />
            </div>
            {contactButton}
          </div>
        </div>
        <Separator className="mb-2 mt-8 md:hidden" />
        <div className="flex flex-col md:flex-row items-center justify-center pb-2 text-xs md:text-base text-nowrap">
          <span>copyright © {new Date().getFullYear()} </span>
          <Logo width={120} className="h-full md:ml-1 md:mr-1" />
          <span>Tous droits réservés</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
