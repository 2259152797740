'use client';
import { useState, useMemo, useCallback } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Logo from '@/components/common/Logo';
import { Button } from '@/components/ui/button';
import { usePathname } from 'next/navigation';

// Importations dynamiques avec suspense
const MaltIcon = dynamic(() => import('@/components/icons/MaltIcon'), {
  ssr: true,
  loading: () => <div className="w-5 h-5" />,
});

const CallToAction = dynamic(
  () =>
    import('@/components/common/CallToAction').then((mod) => mod.CallToAction),
  {
    ssr: true,
    loading: () => (
      <div className="w-32 h-10 animate-pulse bg-primary/10 rounded-md" />
    ),
  },
);

// Importations dynamiques des icônes avec preload
const IoHome = dynamic(
  () => import('react-icons/io5').then((mod) => mod.IoHome),
  {
    ssr: true,
  },
);
const IoMail = dynamic(
  () => import('react-icons/io5').then((mod) => mod.IoMail),
  {
    ssr: true,
  },
);
const IoBook = dynamic(
  () => import('react-icons/io5').then((mod) => mod.IoBook),
  {
    ssr: true,
  },
);
const IoPerson = dynamic(
  () => import('react-icons/io5').then((mod) => mod.IoPerson),
  {
    ssr: true,
  },
);
const IoStar = dynamic(
  () => import('react-icons/io5').then((mod) => mod.IoStar),
  {
    ssr: true,
  },
);
const BsGithub = dynamic(
  () => import('react-icons/bs').then((mod) => mod.BsGithub),
  {
    ssr: true,
  },
);
const BsLinkedin = dynamic(
  () => import('react-icons/bs').then((mod) => mod.BsLinkedin),
  {
    ssr: true,
  },
);

// Importation dynamique des composants Sheet
const DynamicSheet = dynamic(
  () => import('@/components/ui/sheet').then((mod) => mod.Sheet),
  {
    ssr: false,
    loading: () => (
      <div className="w-10 h-10 animate-pulse bg-background/80 rounded-full" />
    ),
  },
);
const DynamicSheetContent = dynamic(
  () => import('@/components/ui/sheet').then((mod) => mod.SheetContent),
  { ssr: false },
);
const DynamicSheetTrigger = dynamic(
  () => import('@/components/ui/sheet').then((mod) => mod.SheetTrigger),
  { ssr: false },
);

// Importation dynamique des icônes de menu
const RiMenuFill = dynamic(() =>
  import('react-icons/ri').then((mod) => mod.RiMenuFill),
);
const RiCloseFill = dynamic(() =>
  import('react-icons/ri').then((mod) => mod.RiCloseFill),
);

// Définition du type pour les liens
interface NavLink {
  href: string;
  label: string;
  icon?: React.ElementType;
}

// Tableau des liens de navigation
const navLinks: NavLink[] = [
  { href: '/', label: 'Accueil', icon: IoHome },
  { href: '/a-propos', label: 'À propos', icon: IoPerson },
  { href: '/avis', label: 'Avis', icon: IoStar },
  { href: '/projects', label: 'Projets', icon: IoBook },
  { href: '/contact', label: 'Contact', icon: IoMail },
];

// Tableau des liens sociaux
const socialLinks = [
  {
    href: 'https://github.com/ClementG91',
    label: 'Github',
    icon: BsGithub,
  },
  {
    href: 'https://www.linkedin.com/in/clementgenest/',
    label: 'Linkedin',
    icon: BsLinkedin,
  },
  {
    href: 'https://www.malt.fr/profile/clementgenest',
    label: 'Malt',
    icon: MaltIcon,
  },
];

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const currentPath = usePathname();

  const getBasePath = useCallback((path: string) => {
    const match = path.match(/^\/[^\/]*/);
    return match ? match[0] : '/';
  }, []);

  const isActive = useCallback(
    (path: string) => {
      const basePath = getBasePath(currentPath);
      return basePath === path
        ? 'text-foreground dark:text-foreground text-lg font-bold'
        : 'text-foreground/80 text-lg font-light';
    },
    [currentPath, getBasePath],
  );

  const MemoizedLogo = useMemo(() => <Logo className="w-[190px] h-auto" />, []);
  const MemoizedMenuIcon = useMemo(
    () =>
      isMobileMenuOpen ? <RiCloseFill size={24} /> : <RiMenuFill size={24} />,
    [isMobileMenuOpen],
  );

  return (
    <nav className="fixed top-0 z-40 mb-4 w-full border-b bg-background/80 backdrop-blur py-3 shadow">
      <div className="flex items-center justify-between px-2 lg:grid lg:grid-cols-3 lg:px-4">
        {MemoizedLogo}

        {/* Navigation desktop */}
        <div className="hidden w-full flex-wrap items-center justify-end gap-8 lg:flex lg:flex-nowrap lg:col-span-2">
          {navLinks.map((link) => (
            <Link
              key={link.href}
              href={link.href}
              className={`${isActive(link.href)} ${
                link.href === '/a-propos' ? 'text-nowrap' : ''
              }`}
            >
              {link.label}
            </Link>
          ))}
          <CallToAction href="/contact" />
        </div>

        {/* Nouveau menu mobile avec Sheet */}
        <div className="flex gap-2 lg:hidden">
          <DynamicSheet
            open={isMobileMenuOpen}
            onOpenChange={setIsMobileMenuOpen}
          >
            <DynamicSheetTrigger asChild>
              <Button
                variant="link"
                aria-label="Menu"
                className="rounded-full border-2 px-2 py-4 text-foreground"
              >
                {MemoizedMenuIcon}
              </Button>
            </DynamicSheetTrigger>
            <DynamicSheetContent
              side="right"
              className="w-[300px] sm:w-[400px] pt-16"
            >
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-2">
                  {navLinks.map((link) => (
                    <Button
                      key={link.href}
                      variant="link"
                      className={`${isActive(link.href)} justify-start`}
                      asChild
                    >
                      <Link
                        href={link.href}
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        <p className="flex items-center gap-2">
                          {link.icon && <link.icon size={18} />}
                          {link.label}
                        </p>
                      </Link>
                    </Button>
                  ))}
                </div>

                {/* Informations de contact mobile */}
                <div className="flex flex-col items-center px-4 text-center">
                  <div className="flex flex-col items-center justify-center gap-4">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <Link
                          href="mailto:contact@edeveloppe.com"
                          className="text-lg underline"
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          contact@edeveloppe.com
                        </Link>
                        <Link
                          href="tel:06 42 80 55 76"
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          06 42 80 55 76
                        </Link>
                        <div className="font-normal">Paris</div>
                      </div>
                    </div>

                    {/* Liens sociaux mobile */}
                    <div className="flex gap-4">
                      {socialLinks.map((social) => (
                        <Button
                          key={social.href}
                          variant="outline"
                          className="rounded-full border-2 px-2 py-4 shadow-md hover:border-primary"
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          <Link
                            href={social.href}
                            aria-label={social.label}
                            target="_blank"
                            rel="noopener"
                          >
                            <social.icon size={20} />
                          </Link>
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-center px-4">
                  <CallToAction href="/contact" />
                </div>
              </div>
            </DynamicSheetContent>
          </DynamicSheet>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
